import { v4 as uuidv4 } from 'uuid';
import { countries } from './countries';

// admin roles
const adminRolesSelect = [
    {
        value : "lo",
        text : "Loan Officer"
    },
    {
        value : "pm",
        text : "Portfolio Manager"
    },
    {
        value : "hoc",
        text : "Head of Credit"
    },
    {
        value : "crm",
        text : "Credit Risk Manager"
    },
    {
        value : "hor",
        text : "Head of Risk"
    },
]

const validateEmail = (email) => {
    // eslint-disable-next-line
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(email.toLowerCase());
}

const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}

const loanAmount = (amountReq, amountElig) => {
    return Math.min(parseFloat(amountReq), amountElig);
}

// education stages 
const education = [
    { id : uuidv4(), value : "tertiary", name : "Tertiary" },
    { id : uuidv4(), value : "shs", name : "SHS" },
    { id : uuidv4(), value : "jhs", name : "JHS" },
    { id : uuidv4(), value : "primary", name : "Primary" }
]

// employment nature
const employment = [
    { id : uuidv4(), value : "fullTime", name : "Full Time" },
    { id : uuidv4(), value : "casual", name : "Casual" }
]

// gender
const genderRoles = [
    { id : uuidv4(), value : "male", name : "Male" },
    { id : uuidv4(), value : "female", name : "Female" },
    { id : uuidv4(), value : "n/a", name : "Rather not say" }
]

// marital status
const maritalStatusOpts = [
    { id : uuidv4(), value : "single", name : "Single" },
    { id : uuidv4(), value : "married", name : "Married" },
    { id : uuidv4(), value : "divorced", name : "Divorced" },
    { id : uuidv4(), value : "widowed", name : "Widowed" },
    { id : uuidv4(), value : "n/a", name : "Rather not say" },
]

// Identification Types
const idCards = [
    { id : uuidv4(), value : "passport", name : "Passport" },
    { id : uuidv4(), value : "driverlicence", name : "Driver's license" },
    { id : uuidv4(), value : "ssnit", name : "SSNIT" },
    { id : uuidv4(), value : "ghanacard", name : "Ghana Card" },
    { id : uuidv4(), value : "voter", name : "Voter's ID Card (New)" },
    { id : uuidv4(), value : "oldvoter", name : "Voter's ID Card (Old)" },
]

// Occupation
const workStates = [
    { id : uuidv4(), value : "business", name : "Business" },
    { id : uuidv4(), value : "medical", name : "Medical" },
    { id : uuidv4(), value : "technology", name : "Technology" },
    { id : uuidv4(), value : "service", name : "Service" },
    { id : uuidv4(), value : "other", name : "Other" },
]

// Network Providers
const networkProviders = [
    { id : uuidv4(), value : "mtn", name : "MTN" },
    { id : uuidv4(), value : "telecel", name : "Telecel" },
    { id : uuidv4(), value : "airtel", name : "AirtelTigo" },
    { id : uuidv4(), value : "glo", name : "Glo" },
]

// Countries
const country = () => {
    for (let i = 0; i < countries.length; i++) {
         countries[i].id = uuidv4();
         countries[i].value = countries[i].name
    }
    return countries;
}

const nations = country();

// loan stages
const loanStages = {
    initial : "pre-approved",
    pm : "pm approved",
    hoc : "head of credit approved",
    crm : "credit risk manager approved",
    hor : "head of risk approved",
    facility : "facility letter submitted",
    disbursement : "await disbursement",
    disbursed : "loan disbursed",
    active : "active",
    repaid : "repaid"
}

// loan stage (old)
const loanStatesOld = {
    initial : "initial",
    pendingApprove : "pendingApprove",
    approved : "approved",
    active : "active",
    completed : "completed",
    rejected : "rejected",
    terminated : "terminated",
}

// admin roles
const adminRoles = {
    pm : "pm",
    hoc : "hoc",
    crm : "crm",
    hor : "hor",
}

const loanTypesOld = {
    loanme : "LoanMe",
    pension : "Pension",
    pencash : "Pension",
    capitalme : "CapitalMe",
    cediscredit : "CedisCredit",
    cashme : "CashMe",
    investment : "Investment",
    employer : "Employer",
    ploc : "PLOC"
}

const loanStageRev = (status, stage) => {
    // stage is loanApprovalState, status is applicationStage (rotate)
    // status remains completed till hor approves

    return (status === "completed" && stage === "pre-approved") ? "Awaiting PM" :
            (status === "completed" && stage === "pm approved") ? "Awaiting HOC" : 
            (status === "completed" && stage === "head of credit approved") ? "Awaiting CRM" :
            (status === "completed" && stage === "credit risk manager approved") ? "Awaiting HOR" :
            (status === "facility letter submitted" && stage === "head of risk approved") ? "Facility letter signed" :
            (status === "await disbursement" && stage === "head of risk approved") ? "Await Disbursement" :
            (status === "loan disbursed" && stage === "head of risk approved") ? "active" : "completed"
    
    // return stage === "pre-approved" ? "PM" : 
    //         stage === "pm approved" ? "HOC" :
    //         stage === "head of credit approved" ? "CRM" :
    //         stage === "credit risk manager approved" ? "HOR" :
    //         stage === "head of risk approved" && status === "await disbursement" ? "Await Disbursement" :
    //         stage === "head of risk approved" && status === "loan disbursed" ? "active" :
    //         stage === "head of risk approved" ? "facility letter signed" :
    //         stage === "facility letter submitted" ? "Disbursement" : "active";      
}

const loanStates = {
    preApproved : "pre-approved",
    rejected : "rejected",
    active : "active",
    overdue : "overdue",
    repaid : "repaid",
    canceled : "canceled",
    restructured : "restructured",
    writtenoff : "writtenoff",
    facility : "facility",
    disburse : "disburse",
    completed : "completed"
}

const loanFilters = [
    {id : uuidv4(), name : "LoanMe", value : "loanme", limit : 20000, rate : 4},
    {id : uuidv4(), name : "Pension", value : "pension", limit : 20000, rate : 1.8},
    {id : uuidv4(), name : "Pension Cash", value : "pencash", limit : 20000, rate : 3},
    {id : uuidv4(), name : "CapitalMe", value : "capitalme", limit :20000, rate : 6},
    {id : uuidv4(), name : "CedisCredit", value : "cediscredit", limit : 3000, rate : 6},
    {id : uuidv4(), name : "CashMe", value : "cashme", limit : 3000, rate : 5},
    {id : uuidv4(), name : "Investment", value : "investment", limit : 20000, rate : 2.5},
    {id : uuidv4(), name : "Employer", value : "employer", limit : 10000, rate : 4},
    {id : uuidv4(), name : "Salaried", value : "salaried", limit : 20000, rate : 4},
    // {id : uuidv4(), name : "Ploc", value : "ploc"},
]

const collectionStates = [
    { id : uuidv4(), value: false, name: "All" }, 
    { id : uuidv4(), value: true, name: "Unmatched" }
]

const options = { year: 'numeric', month: 'short', day: 'numeric' };

const dateToHumanFormat = (date) => {
    return new Date(date).toLocaleDateString(undefined, options);
}

const dateToHumanFormatShort = (date) => {
    return new Date(date).toLocaleDateString(undefined, { month : "short", day : "numeric" })
}

const dateToHumanFormatShortWithTime = (date) => {
    return new Date(date).toLocaleDateString(undefined, { month : "short", day : "numeric", hour12 : false, hour : "2-digit", minute : "2-digit" });
}

const getFileExtension = (file) => {
    return file?.split('.').pop();
}

const loanTypes = {
    loanme : "loanMe",
    capitalme : "capitalMe",
    capitalMeTopUp : "capitalMeTopUp",
    cashme : "cashMe",
    pension : "pension",
    insurance : "insurance",
    employerDeduction : "employerDeduction",
    investmentOrSavingsBacked : "investmentOrSavingsBacked",
    cedisCredit : "cedisCredit"
}

const loanTypesRev = (loan) => {
    return loan === "loanMe" ? "loanme" : 
            loan === "capitalMe" ? "capitalme" : 
            loan === "capitalMeTopUp" ? "capitalme-topup" : 
            loan === "cashMe" ? "cashme" : 
            loan === "pension" ? "pension" : 
            loan === "employerDeduction" ? "employer-deduction" : 
            loan === "cedisCredit" ? "momo-cedis-credit" :
            "investment"
}

const staffRoles = (role) => {
    let state = Array.isArray(role) ? role[0] : role;
    state = state === "admin" ? "Admin" : 
            state === "treasurer" ? "Treasurer" : 
            state === "loan_officer" ? "Loan Officer" : 
            state === "portfolio_manager" ? "Portfolio Manager" : 
            state === "head_of_credit" ? 'Head of Credit' : 
            state === "credit_risk_manager" ? "Credit Risk Manager" : 
            state === "head_of_risk" ? "Head of Risk" : 
            "Client";
    return state;
}

function removeElementFrmArray(array, item) {
    const index = array.indexOf(item);
    if (index > -1) { 
        array.splice(index, 1); 
    }

    return array;
}

function authStaff(accessToken) {
    return {
        "Content-Type" : "application/json",
        "authorization" : `${accessToken}`
    }
}

function generateSlug(text){
    return text.replace(/ /g,"_").toLowerCase();
}

function startTimer(duration, display) {
    var timer = duration, minutes, seconds;
    setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
            timer = duration;
        }
    }, 1000);
}

function creditRow(num, document) {
    return `pmEval?.evaluationOfCreditHistory?.evaluationOfCreditHistoryP${num}${document}`;
}

function getUserChartData(users){
    let active = 0;
    let inactive = 0;

    for (let i = 0; i < users.length; i++) {
        if(users[i].accountVerified === true){
            active += 1
        } else {
            inactive += 1
        }
    }
    
    return { active, inactive }
}

function getLoanBalance(payments){
    return payments.reduce((acc, curr) => acc + parseFloat(curr.payment), 0).toFixed(2);
}

function roundNum(num, precisionIn){
    let precision = precisionIn || 2;
    let rounded = (Math.round(num * Math.pow(10, precision)) / Math.pow(10,precision)).toFixed(precision);
    return parseFloat(rounded);
}

function hyphenDate(){
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    let dateGen = `${day}-${month}-${year}`;

    return dateGen;
}

function phoneNumFormatter(num){
    let phone = num?.replace(/^.{3}/g, '0');
    // staffPhone = staffPhone.replace(/^.{3}/g, '0');
    return phone
}

function numFormatter(num) {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const cediSign = "₵";

function percentDaily(pastTotal, presentTotal){
    
    let percDiff = 100 * Math.abs((presentTotal - pastTotal) / ((presentTotal + pastTotal) / 2));
    return isNaN(percDiff) ? 0 : roundNum(percDiff, 1);
}

function getLoansDisbursed(loans){
    let date = new Date();
    let today = Date.parse(date);
    let yesterday = Date.parse(date.setDate(date.getDate() - 1));

    let todayLoans = loans.filter((loan) => loan?.disbursement?.length > 0);
    todayLoans = todayLoans.filter((loan) => Date.parse(loan?.disbursement[0]?.createdAt) === today);
    // console.log(todayLoans);

    let prevDayLoans = loans.filter((loan) => loan?.disbursement?.length > 0);
    prevDayLoans = prevDayLoans.filter((loan) => Date.parse(loan?.disbursement[0]?.createdAt) === yesterday);
    // console.log(prevDayLoans);
    
    let prevTotal = prevDayLoans.reduce((sum, loan)=> sum + loan?.disbursement[0]?.amount, 0);
    let todayTotal = todayLoans.reduce((sum, loan)=> sum + loan?.disbursement[0]?.amount, 0);

    return { prevTotal, todayTotal };
}

// types of main reports
const reportTypes = [
    { id : uuidv4(), value : "daily", name : "Daily" },
    { id : uuidv4(), value : "weekly", name : "Weekly" },
    { id : uuidv4(), value : "monthly", name : "Monthly" },
    { id : uuidv4(), value : "yearly", name : "Yearly" },
    { id : uuidv4(), value : "arrears", name : "Arrears" },
    { id : uuidv4(), value : "payouts", name : "Daily Repayments" },
    { id : uuidv4(), value : "credit", name : "Credit Bureau" },
    { id : uuidv4(), value : "creditDnB", name : "Credit Bureau (DnB)" },
    { id : uuidv4(), value : "active", name : "Active Loans" },
    { id : uuidv4(), value : "completed", name : "Completed Loans" },
    { id : uuidv4(), value : "expectedpayout", name : "Expected Payout" },
    { id : uuidv4(), value : "bog", name : "Bank of Ghana" },
    { id : uuidv4(), value : "custseg", name : "Customer Segmentation" },
    { id : uuidv4(), value : "achieve", name : "Achieve Loans" },
    { id : uuidv4(), value : "petra", name : "Petra Loans" },
    { id : uuidv4(), value : "pending" , name : "All Loans pending disbursement" }
]

// types of other reports
const otherReportTypes = [
    { id : uuidv4(), value : "disburse", name : "Disbursement" },
    { id : uuidv4(), value : "repayment", name : "Repayment" },
]

// emails of staff who can approve/deny reversal of transactions
const reversalAdmins = [
    "s.kadiru@cedispay.com.gh",
    "f.egyir@cedispay.com.gh",
    "kwadwo@asantekwabiah.com", // temporal
    "admin@cedispay.com.gh" // temporal
]

const getDateAfterTimeframe = (timeframe) => {
    timeframe = timeframe.toLowerCase();
    const currentDate = new Date();
    let dateAfterTimeframe;

    if(timeframe === 'daily'){
        dateAfterTimeframe = new Date(currentDate.getTime() + (1 * 24 * 60 * 60 * 1000));
    } else if (timeframe === 'weekly') {
        dateAfterTimeframe = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000));
    } else if(timeframe === 'biweekly'){
        dateAfterTimeframe = new Date(currentDate.getTime() + (14 * 24 * 60 * 60 * 1000));
    } else if (timeframe === 'monthly') {
        dateAfterTimeframe = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
    } else if (timeframe === 'quarterly') {
        dateAfterTimeframe = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());
    } else {
        throw new Error('Invalid timeframe');
    }

    return dateAfterTimeframe.toISOString().split('T')[0];
}

function getLastDayOfMonth(year, month) {
    // Create a new Date object for the first day of the next month
    const nextMonth = new Date(year, month, 1);
    
    // Subtract 1 day from the first day of the next month
    const lastDayOfMonth = new Date(nextMonth - 1);
    
    // Return the day component of the last day of the month
    return lastDayOfMonth.getDate();
}

const nextMonthStartDate = () => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();

    let lastDay = getLastDayOfMonth(year, month);

    return new Date(year, month + 1, lastDay).toISOString().split("T")[0];
}

const fileSize = (size) => {
    let sizeInMB = (size / (1024 * 1024)).toFixed(2);
    return sizeInMB + 'MB';
}

const sortSubSectorOpts = (sector) => {
    return sector === "agric" ? argicSubSectorOpts : 
            sector === "mining" ? miningSubSectorOpts :
            sector === "manufacturing" ? manufacturingSubSectorOpts :
            sector === "construction" ? constructionSubSectorOpts :
            sector === "electricity" ? electricitySubSectorOpts :
            sector === "commerce" ? commerceSubSectorOpts :
            sector === "transport" ? transportSubSectorOpts :
            sector === "services" ? servicesSubSectorOpts : [{ value : "other", text : "Other" }];
}

function getFirstFiveDaysOfMonth(year, month){
    let days = [];
    let date = new Date(year, month, 1);
    let count = 0;

    while(count < 5){
        if(date.getDay() !== 0 && date.getDay() !== 6){
            days.push(date.getDate());
            count++
        }
        date.setDate(date.getDate() + 1);
    }

    return days;
}

function getAllDates(item){
    let newArr, temp = [];
    for(let i = Math.min(...item); i <= Math.max(...item); i++){
        if(!item.includes(i)){
            temp.push(i);
        }
    }

    let nArr = item.concat(temp);
    newArr = nArr.sort((a, b) => a - b);
    return newArr;
}

function formatName(name) {
    // Remove leading and trailing spaces
    name = name.trim().toLowerCase();

    // Capitalize each first letter
    name = name.replace(/\b\w/g, (letter) => letter.toUpperCase());

    return name;
}

function getDateAndTime(dateTime) {
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
}

const repaymentAmtLimit = 30000;


// loan application constants
const optionsYN = [
    {
        value : "Yes",
        name : "Yes"
    },
    {
        value : "No",
        name : "No"
    }
]

const balanceOpts = [
    {
        value : "below3k",
        text : "Below 3,000"
    },
    {
        value : "max5k",
        text : "GHS 3,000 - 5,000"
    },
    {
        value : "max10k",
        text : "GHS 5,000 - 10,000"
    },
    {
        value : "max15k",
        text : "GHS 10,000 - 15,000"
    },
    {
        value : "above15k",
        text : "Above GHS 15,000"
    }
]

const numOfYearsWithEmployer = [
    {
        value : "below1",
        text : "Below 1 Year"
    },
    {
        value : "max5",
        text : "1 - 5 Years"
    },
    {
        value : "max10",
        text : "5 - 10 Years"
    },
    {
        value : "above10",
        text : "Above 10 Years"
    }
]

const sectorOpts = [
    {
        value : "agric",
        text : "Agriculture"
    },
    {
        value : "industry",
        text : "Industry"
    },
    {
        value : "trade",
        text : "Trade, Repair of Vehicles, Household Goods"
    },
    {
        value : "hotels",
        text : "Hotels and Restaurants"
    },
    {
        value : "transport",
        text : "Transport, Storage and Communications"
    },
    {
        value : "tech",
        text : "Information and Communication"
    },
    {
        value : "financial",
        text : "Financial and Insurance Activities"
    },
    {
        value : "estate",
        text : "Real Estate Activities"
    },
    {
        value : "professional",
        text : "Professional, Administrative and Support Service activities"
    },
    {
        value : "public",
        text : "Public Administration, Defense and Social Security"
    },
    {
        value : "education",
        text : "Education"
    },
    {
        value : "health",
        text : "Health and Social Work"
    },
    {
        value : "other",
        text : "Other Personal Service Activities"
    }
]

const missedPaymentOptions = [
    {
        value : "noMiss",
        text : "No missed payments"
    },
    {
        value : "max1",
        text : "1 missed payment"
    },
    {
        value : "max2",
        text : "2 missed payments"
    },
    {
        value : "missedMore",
        text : "More than 2 missed payments"
    },
    {
        value : "noCred",
        text : "No payment history"
    }
]

const billPaymentOptions = [
    {
        value : "Rent Payments",
        text : "Rent Payments"
    },
    {
        value : "Electricity bills",
        text : "Electricity bills"
    },
    {
        value : "Water bills",
        text : "Water bills"
    },
    {
        value : "Postpaid mobile phone bills",
        text : "Postpaid mobile phone bills"
    },
    {
        value : "Postpaid TV subscription bills",
        text : "Postpaid TV subscription bills"
    },
    {
        value : "Postpaid internet subscription",
        text : "Postpaid internet subscription"
    },
    {
        value : "Tax installment payment",
        text : "Tax installment payment"
    },
    {
        value : "Due-paying association",
        text : "Due-paying association"
    },
    {
        value : "Other goods and services paid on an installment basis",
        text : "Other goods and services paid on an installment basis"
    },
    {
        value : "None of the above",
        text : "None of the above"
    }
]

const billsHistoryOpts = [
    {
        value : "noHist",
        text : "No payment history"
    },
    {
        value : "less3",
        text : "Less than 3 months"
    },
    {
        value : "max2",
        text : "3 months - 2 years"
    },
    {
        value : "max4",
        text : "2 years - 4 years"
    },
    {
        value : "above4",
        text : "Above 4 years"
    }
]

const insuranceOpts = [
    {
        value : "Employer - Sponsored Insurance",
        text : "Employer - Sponsored Insurance"
    },
    {
        value : "Funeral Insurance for Relatives",
        text : "Funeral Insurance for Relatives"
    },
    {
        value : "Job Loss Insurance",
        text : "Job Loss Insurance"
    },
    {
        value : "Disability Insurance",
        text : "Disability Insurance"
    },
    {
        value : "Comprehensive Property Insurance for Business",
        text : "Comprehensive Property Insurance for Business"
    },
    {
        value : "None",
        text : "None"
    }
]

const purposeOfLoanOpts = [
    {
        value : "Business Expansion",
        text : "Business Expansion"
    },
    {
        value : "Home Improvement",
        text : "Home Improvement"
    },
    {
        value : "Education",
        text : "Education"
    },
    {
        value : "Professional Development",
        text : "Professional Development"
    },
    {
        value : "Medical Expenses",
        text : "Medical Expenses"
    },
    {
        value : "Debt Consolidation",
        text : "Debt Consolidation"
    },
    {
        value : "Investment Opportunity",
        text : "Investment Opportunity"
    },
    {
        value : "Asset Acquisition",
        text : "Asset Acquisition"
    },
    {
        value : "Travel and Vacation",
        text : "Travel and Vacation"
    },
    {
        value : "Emergency Funds",
        text : "Emergency Funds"
    },
    {
        value : "Other",
        text : "Other"
    }
]

const paymentPlanOpts = [
    {
        value : "Daily",
        text : "Daily"
    },
    {
        value : "Weekly",
        text : "Weekly"
    },
    {
        value : "Biweekly",
        text : "Bi-weekly"
    },
    {
        value : "Monthly",
        text : "Monthly"
    },
    {
        value : "Quarterly",
        text : "Quarterly"
    }
]

const paymentDurationOpts = [
    {
        value : "1",
        text : "1 month"
    },
    {
        value : "2",
        text : "2 months"
    },
    {
        value : "3",
        text : "3 months"
    },
    {
        value : "4",
        text : "4 months"
    },
    {
        value : "5",
        text : "5 months"
    },
    {
        value : "6",
        text : "6 months"
    },
    {
        value : "7",
        text : "7 months"
    },
    {
        value : "8",
        text : "8 months"
    },
    {
        value : "9",
        text : "9 months"
    },
    {
        value : "10",
        text : "10 months"
    },
    {
        value : "11",
        text : "11 months"
    },
    {
        value : "12",
        text : "12 months"
    }
]

const incomeFrequencyOpts = [
    {
        value : "Regular",
        text : "Regular"
    },
    {
        value : "Irregular",
        text : "Irregular"
    }
]

const automaticPaymentOpts = [
    {
        value : "Standing Order",
        text : "Standing Order"
    },
    {
        value : "Employer payroll deduction",
        text : "Employer payroll deduction"
    },
    {
        value : "Mobile Money Auto-Deduction",
        text : "Mobile Money Auto-Deduction"
    }
]

const salariedOpts = [
    {
        value : "Guarantor",
        text : "Guarantor"
    }
]

const relationshipManagers = [
    {
        value : "m.dogbey@cedispay.com.gh",
        text : "Mark Dogbey"
    },
    {
        value : "d.quayson@cedispay.com.gh",
        text : "Doris Quayson"
    },
    {
        value : "afya.agyapomaa@cedispay.com.gh",
        text : "Afya Agyapomaa"
    }
]

const pensionContYrsOpts = [
    {
        value : "below1",
        text : "Below 1 Year"
    },
    {
        value : "max5",
        text : "1 - 5 Years"
    },
    {
        value : "max10",
        text : "5 - 10 Years"
    },
    {
        value : "above10",
        text : "Above 10 Years"
    }
]

const pensionContOneYrOpts = [
    {
        value : "monthly",
        text : "Regular and consistent monthly contributions"
    },
    {
        value : "quarterly",
        text : "Regular and consistent quarterly contributions"
    },
    {
        value : "biyearly",
        text : "Regular and consistent contributions every six months"
    },
    {
        value : "occasional",
        text : "Occasional gaps in contributions but mostly consistent throughout the year"
    },
    {
        value : "inconsistent",
        text : "Inconsistent contributions with significant periods of no contributions"
    }
]

const avgPensionContOpts = [
    {
        value : "below500",
        text : "Below GHC 500"
    },
    {
        value : "max1k",
        text : "GHC 500 - GHC 1,000"
    },
    {
        value : "above1k",
        text : "Above GHC 1,000"
    }
]

const pensionCollateralOpts = [
    {
        value : "Investments",
        text : "Petra Opportunity Pension Schema (Investment backed)"
    },
    {
        value : "Savings",
        text : "Savings Booster (Savings backed)"
    },
]

const pensionStateOpts = [
    {
        value : "secured",
        text : "Secured Loan"
    },
    {
        value : "semi-secured",
        text : "Semi-Secured Loan"
    },
    {
        value : "unsecured",
        text : "Unsecured Loan"
    }
]

const pensionLoanType = [
    {
        value : "Normal Pension",
        text : "Normal Pension"
    },
    {
        value : "CedisPay Client",
        text : "CedisPay Client"
    },    
    {
        value : "Pension Promo",
        text : "Pension Promo"
    },
    {
        value : "Petra",
        text : "Petra"
    },
    {
        value : "Axis",
        text : "Axis"
    },
    {
        value : "Metropolitan",
        text : "Metropolitan"
    },
    {
        value : "Old Mutual",
        text : "Old Mutual"
    }
]

const pensionCompanies = [
    {
        value : "Enterprise Trustees Limited",
        text : "Enterprise Trustees Limited"
    },
    {
        value : "Axis Pension Trust",
        text : "Axis Pension Trust"
    },
    {
        value : "Petra Trust Co. Ltd",
        text : "Petra Trust Co. Ltd"
    },
    {
        value : "United Trustees Gh Ltd",
        text : "United Trustees Gh Ltd"
    },
    {
        value : "Negotiated Benefit Trust Co. Ltd",
        text : "Negotiated Benefit Trust Co. Ltd"
    },
    {
        value : "Metropolitan Pensions Trust Gh Ltd",
        text : "Metropolitan Pensions Trust Gh Ltd"
    },
    {
        value : "Secure Pension Trust",
        text : "Secure Pension Trust"
    },
    {
        value : "Old Mutual Pensions Trust Gh Ltd",
        text : "Old Mutual Pensions Trust Gh Ltd"
    },
    {
        value : "Pensions Alliance Trust Limited",
        text : "Pensions Alliance Trust Limited"
    },
    {
        value : "Capel Pension Trust",
        text : "Capel Pension Trust"
    },
    {
        value : "Others",
        text : "Others"
    }
]

const transPerMonthOpts = [
    {
        value : "5",
        text : "Between 0 - 10 transactions"
    },
    {
        value : "15",
        text : "Between 10 - 20 transactions"
    },
    {
        value : "20",
        text : "Between 20 - 30 transactions"
    },
    {
        value : "30",
        text : "Above 30 transactions"
    }
]

const minimumAmtPerTransOpts = [
    {
        value : "5",
        text : "Between GHC 5 - 10"
    },
    {
        value : "15",
        text : "Between GHC 10 - 20"
    },
    {
        value : "20",
        text : "Between GHC 20 - 30"
    },
    {
        value : "30",
        text : "Above GHC 30"
    }
]

const averageBalanceOpts = [
    {
        value : "below250",
        text : "Below GHC 250"
    },
    {
        value : "max500",
        text : "GHC 250 - 500"
    },
    {
        value : "max750",
        text : "GHC 500 - 750"
    },
    {
        value : "max1k",
        text : "GHC 750 - 1,000"
    },
    {
        value : "above1k",
        text : "Above GHC 1,000"
    }
]

const incomeConsistencyOpts = [
    {
        value : "10",
        text : "Very volatile (Over 80% fluctuation)"
    },
    {
        value : "20",
        text : "Somewhat volatile (60% - 80% fluctuation)"
    },
    {
        value : "30",
        text : "Moderately volatile (40% - 60% fluctuation)"
    },
    {
        value : "40",
        text : "Consistently low (0% - 40% fluctuation)"
    }
]

const plocYearsInBusinessOpts = [
    {
        value : "below1",
        text : "Below 1 Year"
    },
    {
        value : "max5",
        text : "1 - 5 Years"
    },
    {
        value : "max10",
        text : "5 - 10 Years"
    },
    {
        value : "above10",
        text : "Above 10 Years"
    }
]

const momoTransactionOpts = [
    {
        value : "zeroTrans",
        text : "0 transactions" 
    },
    {
        value : "more1",
        text : "1 - 5 transactions"
    },
    {
        value : "more5",
        text : "5 - 10 transactions"
    },
    {
        value : "more15",
        text : "15 - 20 transactions"
    },
    {
        value : "more20",
        text : "Above 20 transactions"
    }
]

const yearsInBusinessOpts = [
    {
        value : "max1",
        text : "One month or less"
    },
    {
        value : "max2",
        text : "1 month - 2 months"
    },
    {
        value : "above3",
        text : "3 months and above"
    }
]

const collateralOpts = [
    {
        value : "Investments",
        text : "Investments"
    },
    {
        value : "Savings",
        text : "Savings"
    }
]

const investmentSizeOpts = [
    {
        value : "above10",
        text : "Above GHC 20,000"
    },
    {
        value : "max5",
        text : "Between GHC 10,000 - GHC 20,000"
    },
    {
        value : "below5",
        text : "Below GHC 10,000"
    }
]

const investmentFrequencyOpts = [
    {
        value : "max1",
        text : "Once a month or less"
    },
    {
        value : "max3",
        text : "2 - 3 times a month"
    },
    {
        value : "leastOnce",
        text : "At least once a week"
    }
]

const savingsSizeOpts = [
    {
        value : "less10",
        text : "Below GHC 501"
    },
    {
        value : "max20",
        text : "GHC 501 - GHC 1,000"
    },
    {
        value : 'above20',
        text : "Above GHC 1,000"
    }
]

const savingsFrequencyOpts = [
    {
        value : "always",
        text : "Saving every month without fail"
    },
    {
        value : "occasional",
        text : "Saving in most months with occasional lapses"
    },
    {
        value : "irregular",
        text : "Irregular savings with frequent lapses"
    }
]

const transactionFrequencyOpts = [
    {
        value : "everyday",
        text : "Daily or several times a week"
    },
    {
        value : "twiceWeek",
        text : "2 - 3 times a week"
    },
    {
        value : 'onceWeek',
        text : "Once a week or less"
    }
]

const transactionSizeOpts = [
    {
        value : "below500",
        text : "Below GHC 500"
    },
    {
        value : "max1k",
        text : "GHC 500 - GHC 1,000"
    },
    {
        value : 'above1k',
        text : "Above GHC 1,000"
    }
]

const accountAgeOpts = [
    {
        value : "less1yr",
        text : "Less than 1 year"
    },
    {
        value : "max2yr",
        text : "1 - 2 years"
    },
    {
        value : 'max4yr',
        text : "3 - 4 years"
    },
    {
        value : 'above5yr',
        text : "Above 5 years"
    }
]

const accountActiveOpts = [
    {
        value : 'noneWeek',
        text : "Inactive for more than a week"
    },
    {
        value : "onceWeek",
        text : "Once a week or less"
    },
    {
        value : "twiceWeek",
        text : "2 - 3 times a week"
    },
    {
        value : "everyday",
        text : "Daily or several times a week"
    }
]

const accountBalanceOpts = [
    {
        value : "less1Avg",
        text : "Below GHC 100"
    },
    {
        value : "max1Avg",
        text : "GHC 100 - GHC 1,000"
    },
    {
        value : 'max5Avg',
        text : "GHC 1,000 - GHC 5,000"
    },
    {
        value : 'above5Avg',
        text : "Above GHC 5,000"
    }
]

const investmentLoanOpts = [
    {
        value : "achieve",
        text : "Investment / Savings Backed (Achieve)"
    },
    {
        value : "petra",
        text : "Investment / Savings Backed (Petra)"
    }
]

const payrollLoanOpts = [
    {
        value : "Achieve Customer",
        text : "Achieve Client"
    },
    {
        value : "CedisPay Customer",
        text : "CedisPay Client"
    },
    {
        value : "Promo Employer",
        text : "Employer Promo"
    },
    {
        value : "Employer-Petra",
        text : "Employer (Petra)"
    }
]

const relPensionTrusteeOpts = [
    {
        value : "Yes",
        text : "3 months or more"
    },
    {
        value : "No",
        text : "Less than 3 months"
    }
]

const transFrequencyOpts = [
    {
        value : "freq",
        text : "Frequently (26+ times)"
    },
    {
        value : "regular",
        text : "Regularly (16 - 25 times)"
    },
    {
        value : "rarely",
        text : "Rarely (1 - 5 times), Occasionally"
    }
]

const consistentOpts = [
    {
        value : "consist",
        text : "Consistently low (0% to 40% fluctuation)"
    },
    {
        value : "moderate",
        text : "Moderately fluctuating (41% to 60% variation)"
    },
    {
        value : "somewhat",
        text : "Somewhat fluctuating (61% to 80% variation)"
    },
    {
        value : "volatile",
        text : "Volatile (over 81% variation)"
    }
]

const businessTypeOpts = [
    {
        value : "llc",
        text : "Limited Liability Company (LLC)"
    },
    {
        value : "partnership",
        text : "Partnership"
    },
    {
        value : "soleprop",
        text : "Sole Proprietorship"
    },
    {
        value : "unreg",
        text : "Unregistered"
    }
]

const businessActivityOpts = [
    {
        value : "Essential - general goods ( provision) items",
        text : "Essential - general goods ( provision) items"
    },
    {
        value : "Essentials - perishable foods stuff of 30 days",
        text : "Essentials - perishable foods stuff of 30 days"
    },
    {
        value : "Cosmetic",
        text : "Cosmetic"
    },
    {
        value : "Essential - perishable foods less than 30 days",
        text : "Essential - perishable foods less than 30 days"
    },
    {
        value : "Essential - perishable foods more than 30 days but less than 3 months",
        text : "Essential - perishable foods more than 30 days but less than 3 months"
    },
    {
        value : "Essential not more than 6 months perishable",
        text : "Essential not more than 6 months perishable"
    },
    {
        value : "Essential - non - perishable",
        text : "Essential - non - perishable"
    },
    {
        value : "General supplies",
        text : "General supplies"
    },
    {
        value : "Cooked food",
        text : "Cooked food"
    },
    {
        value : "Clothing",
        text : "Clothing"
    },
    {
        value : "Building materials",
        text : "Building materials"
    },
    {
        value : "Car parts",
        text : "Car parts"
    },
    {
        value : "Uber/taxi or transportation services",
        text : "Uber/taxi or transportation services"
    },
    {
        value : "Dress making",
        text : "Dress making"
    },
    {
        value : "Make-up",
        text : "Make-up"
    },
    {
        value : "Other, please specify",
        text : "Other, please specify"
    },
]

const businessSectorOpts = [
    {
        value : "agric",
        text : "Agriculture, Forestry & Fishing"
    },
    {
        value : "mining",
        text : "Mining & Quarrying"
    },
    {
        value : "manufacturing",
        text : "Manufacturing"
    },
    {
        value : "construction",
        text : "Construction"
    },
    {
        value : "electricity",
        text : "Electricity, Gas & Water"
    },
    {
        value : "commerce",
        text : "Commerce & Finance"
    },
    {
        value : "transport",
        text : "Transport, Storage & Communication"
    },
    {
        value : "services",
        text : "Services"
    },
    {
        value : "misc",
        text : "Miscellaneous"
    }
]

const argicSubSectorOpts = [
    {
        value : "cocoa",
        text : "Cocoa Production"
    },
    {
        value : "livestock",
        text : "Livestock Breeding"
    },
    {
        value : "poultry",
        text : "Poultry Farming"
    },
    {
        value : "forestry",
        text : "Forestry"
    },
    {
        value : "logging",
        text : "Logging"
    },
    {
        value : "fishing",
        text : "Fishing"
    },
    {
        value : "other",
        text : "Other"
    },
]

const miningSubSectorOpts = [
    {
        value : "bauxite",
        text : "Bauxite Mining"
    },
    {
        value : "diamond",
        text : "Diamond Mining"
    },
    {
        value : "gold",
        text : "Gold Mining"
    },
    {
        value : "manganese",
        text : "Manganese Mining"
    },
    {
        value : "quarrying",
        text : "Quarrying"
    },
    {
        value : "other",
        text : "Other"
    },

]

const manufacturingSubSectorOpts = [
    {
        value : "export",
        text : "For Export"
    },
    {
        value : "food",
        text : "Food, Drink & Tobacco"
    },
    {
        value : "textiles",
        text : "Textile, Clothing and Footwear"
    },
    {
        value : "sawmilling",
        text : "Sawmilling & Wood Processing"
    },
    {
        value : "paper",
        text : "Paper, Pulp & Paper Products"
    },
    {
        value : "chemicals",
        text : "Chemicals & Fertilizer Products"
    },
    {
        value : "iron",
        text : "Iron & Steel Products"
    },
    {
        value : "boat",
        text : "Boat / Ship Building & Repair" 
    },
    {
        value : "motor",
        text : "Motor Vehicles"
    },
    {
        value : "other",
        text : "Other"
    },
]

const constructionSubSectorOpts = [
    {
        value : "works",
        text : "Construction & Works"
    },
    {
        value : "building",
        text : "Building Construction"
    }
]

const electricitySubSectorOpts = [
    {
        value : "electric",
        text : "Electric, Light & Power"
    },
    {
        value : "water",
        text : "Water Supply"
    },
    {
        value : "gas",
        text : "Gas Manufacture & Distribution"
    }
]

const commerceSubSectorOpts = [
    {
        value : "import",
        text : "Import Trade"
    },
    {
        value : "export",
        text : "Export Trade"
    },
    {
        value : "cocoa",
        text : "Cocoa Marketing"
    },
    {
        value : "timber",
        text : "Timber Marketing"
    },
    {
        value : "diamond",
        text : "Diamond Marketing"
    },
    {
        value : "mortgage",
        text : "Mortgage Financing"
    },
    {
        value : "otherFinancial",
        text : "Other Financial Institution"
    },
    {
        value : "other",
        text : "Other Unclassified"
    }
]

const transportSubSectorOpts = [
    {
        value : "road",
        text : "Road Transport"
    },
    {
        value : "rail",
        text : "Rail Transport"
    },
    {
        value : "ocean",
        text : "Ocean & Other Water Transport"
    },
    {
        value : "air",
        text : "Air Transport"
    },
    {
        value : "storage",
        text : "Storage & Warehousing"
    },
    {
        value : "communications",
        text : "Communications"
    }
]

const servicesSubSectorOpts = [
    {
        value : "printing",
        text : "Printing, Publishing & Allied Products"
    },
    {
        value : "business",
        text : "Business Services"
    },
    {
        value : "recreation",
        text : "Recreation Services"
    },
    {
        value : "personal",
        text : "Personal Services"
    },
    {
        value : "salary",
        text : "Salary Credit"
    },
    {
        value : "other",
        text : "Other Services(including government)"
    }
]

const employmentLevelOpts = [
    {
        value : "top",
        text : "Top"
    },
    {
        value : "middle",
        text : "Middle"
    },
    {
        value : "lower",
        text : "Lower"
    }
]

const employmentTypeOpts = [
    {
        value : "skilled",
        text : "Skilled"
    },
    {
        value : "unskilled",
        text : "Unskilled"
    }
]

const employmentNatureOpts = [
    {
        value : "fullTime",
        text : "Full Time"
    },
    {
        value : "casual",
        text : "Casual"
    }
]

const demandGoodsOpts = [
    {
        value : "essential",
        text : "Essential"
    },
    {
        value : "nonEssential",
        text : "Non-Essential"
    }
]

const customerTypeOpts = [
    {
        value : "central government",
        text : "Central Government"
    },
    {
        value : "public institutions",
        text : "Public Institutions"
    },
    {
        value : "public enterprises",
        text : "Public Enterprises"
    },
    {
        value : "commerial banks",
        text : "Commerial Banks"
    },
    {
        value : "other depository institutions",
        text : "Other Depository Institutions"
    }
]

const otherFinancialOpts = [
    {
        value : "foreign",
        text : "Foreign"
    },
    {
        value : "indigenous",
        text : "Indigenous"
    },
    {
        value : "households",
        text : "Households"
    }
]

const getInitials = (name) => {
    let initials = name.split(" ").map(word => word[0]).filter((_, index) => index === 0 || index === name.split(" ").length - 1).join("");

    return initials;
}

export { 
    adminRolesSelect,
    validateEmail,
    education,
    employment,
    genderRoles,
    maritalStatusOpts,
    idCards,
    workStates,
    networkProviders,
    nations,

    // loan names
    loanTypes,
    loanTypesRev,
    loanAmount,
    loanStages,
    loanStatesOld,
    adminRoles,
    loanTypesOld,
    loanStageRev,
    loanStates,
    loanFilters,
    collectionStates,

    // staff roles
    staffRoles,

    // random constants
    getRandomIntInclusive,
    dateToHumanFormat,
    dateToHumanFormatShort,
    dateToHumanFormatShortWithTime,
    getFileExtension,
    removeElementFrmArray,
    authStaff,
    generateSlug,
    startTimer,
    creditRow,
    getUserChartData,

    getLoanBalance,
    roundNum,
    hyphenDate,
    phoneNumFormatter,
    numFormatter,
    cediSign,
    percentDaily,
    getLoansDisbursed,
    reportTypes,
    otherReportTypes, 
    
    reversalAdmins,
    getDateAfterTimeframe,
    nextMonthStartDate,
    fileSize,
    sortSubSectorOpts,
    getFirstFiveDaysOfMonth,
    getAllDates,
    formatName,
    getDateAndTime,

    // loan application constants
    optionsYN,
    balanceOpts,
    numOfYearsWithEmployer,
    sectorOpts,
    missedPaymentOptions,
    billPaymentOptions,
    billsHistoryOpts,
    insuranceOpts,
    purposeOfLoanOpts,
    paymentPlanOpts,
    paymentDurationOpts,
    incomeFrequencyOpts,
    automaticPaymentOpts,
    salariedOpts,
    relationshipManagers,
    pensionContYrsOpts,
    pensionContOneYrOpts,
    avgPensionContOpts,
    pensionCollateralOpts,
    pensionStateOpts,
    pensionLoanType,
    pensionCompanies,
    transPerMonthOpts,
    minimumAmtPerTransOpts,
    averageBalanceOpts,
    incomeConsistencyOpts,
    plocYearsInBusinessOpts,
    momoTransactionOpts,
    yearsInBusinessOpts,
    collateralOpts,
    investmentSizeOpts,
    investmentFrequencyOpts,
    savingsSizeOpts,
    savingsFrequencyOpts,
    transactionFrequencyOpts,
    transactionSizeOpts,
    accountAgeOpts,
    accountActiveOpts,
    accountBalanceOpts,
    investmentLoanOpts,
    payrollLoanOpts,
    relPensionTrusteeOpts,
    transFrequencyOpts,
    consistentOpts,
    businessTypeOpts,
    businessActivityOpts,
    businessSectorOpts,
    argicSubSectorOpts,

    miningSubSectorOpts,
    manufacturingSubSectorOpts,
    constructionSubSectorOpts,
    electricitySubSectorOpts,
    commerceSubSectorOpts,
    transportSubSectorOpts,
    servicesSubSectorOpts,

    employmentLevelOpts,
    employmentTypeOpts,
    employmentNatureOpts,
    demandGoodsOpts,
    customerTypeOpts,
    otherFinancialOpts,

    repaymentAmtLimit,
    getInitials
    
}