import React from 'react'
// import { useSelector } from "react-redux";
// import { selectLoan } from "../../../slices/staffSlice";

function Tabs({ loan }) {

    // const loan = useSelector(selectLoan);

    return (
        <div className='rounded-md py-4 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4'>
            <div className='application-bubble'>
                <h6>Loan Amount (GHS)</h6>
                <div className='font-bold'>
                    {loan?.amountReq}
                </div>
            </div>

            <div className='application-bubble'>
                <h6>Months of Loan</h6>
                <div className='font-bold'>
                    {loan?.paymentDuration}
                </div>
            </div>

            <div className='application-bubble'>
                <h6>Monthly Interest Rate</h6>
                <div className='font-bold'>{loan?.monthInterestRate}</div>
            </div>

            <div className='application-bubble'>
                <h6>Scheduled Payments</h6>
                <div className='font-bold'>{loan?.accountInfo?.premium}</div>
            </div>

            <div className='application-bubble'>
                <h6>Processing Fee (GHS)</h6>
                <div className='font-bold'>{loan?.accountInfo?.processingFee}</div>
            </div>

            <div className='application-bubble'>
                <h6>Insurance Fee (GHS)</h6>
                <div className='font-bold'>{loan?.accountInfo?.insuranceFee}</div>
            </div>

            <div className='application-bubble'>
                <h6>Interest Charges (GHS)</h6>
                <div className='font-bold'>{loan?.accountInfo?.sumInterest}</div>
            </div>

            <div className='application-bubble'>
                <h6>Amount Received(GHS)</h6>
                <div className='font-bold'>
                    {loan?.accountInfo?.amountGiven}
                </div>
            </div>

            <div className='application-bubble'>
                <h6>Payment Frequency</h6>
                <div className='font-bold'>
                    {loan?.paymentPlan}
                </div>
            </div>

            <div className='application-bubble'>
                <h6>Total Payments</h6>
                <div className='font-bold'>{loan?.accountInfo?.totalPayment}</div>
            </div>

            <div className='application-bubble'>
                <h6>Number of Payments</h6>
                <div className='font-bold'>{loan?.accountInfo?.numPayments}</div>
            </div>

            {/* show this if loan is a pension loan */}
            {(loan?.type === "Pension" && (loan?.loanKind !== "metropolitan" || loan?.loanKind !== "axis" || loan?.loanKind === "oldMutual")) && (
                <>
                <div className='application-bubble'>
                    <h6>Interest per month</h6>
                    <div className='font-bold'>{loan?.accountInfo?.petraInterestPM}</div>
                </div>

                <div className='application-bubble'>
                    <h6>Commission per month</h6>
                    <div className='font-bold'>{loan?.accountInfo?.petraCommPM}</div>
                </div>

                <div className='application-bubble'>
                    <h6>Commission Total</h6>
                    <div className='font-bold'>{loan?.accountInfo?.petraCommTotal}</div>
                </div>
                </>
            )}
            {/* show this if loan is a pension loan */}

            {/* show this if loan is a Metropolitan loan */}
            {loan?.loanKind === "metropolitan" && (
                <div className='application-bubble'>
                    <h6>Commission on processing fee</h6>
                    <div className='font-bold'>{loan?.accountInfo?.metroProcessingFee}</div>
                </div>
            )}
            {/* show this if loan is a Metropolitan loan */}

            {/* show this if loan is a Axis loan */}
            {loan?.loanKind === "axis" && (
                <div className='application-bubble'>
                    <h6>Commission on processing fee</h6>
                    <div className='font-bold'>{loan?.accountInfo?.axisProcessingFee}</div>
                </div>
            )}
            {/* show this if loan is a Axis loan */}

            {/* show this if loan is a Old Mutual loan */}
            {loan?.loanKind === "oldMutual" && (
                <div className='application-bubble'>
                    <h6>Commission on processing fee</h6>
                    <div className='font-bold'>{loan?.accountInfo?.oldMutualProcessingFee}</div>
                </div>
            )}
            {/* show this if loan is a Old Mutual loan */}
        </div>
    )
}

export default Tabs