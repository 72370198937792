import { useState } from 'react';
import { FormSelect, FormInput, FormUpload, Button, Modal } from "../../components";
import { purposeOfLoanOpts, paymentPlanOpts, relationshipManagers } from '../../utils/constants';
import LoanAuth from "./LoanAuth";
import { preCheckLoanEligibility } from '../../utils/loanProcessing';
import { Dialog } from '@headlessui/react';
import EligCheck from './EligCheck';
import { useDispatch } from 'react-redux';
import { show } from '../../slices/toastSlice';
import NextOfKin from './NextOfKin';

function CedisCredit({ client }) {

    let [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const closeModal = () => {
        setIsOpen(false)
    }

    const openModal = () => {
        setIsOpen(true)
    }

    const [purpose, setPurpose] = useState('');
    const [paymentPlan, setPaymentPlan] = useState('');
    // const [preferredNetwork, setPreferredNetwork] = useState('');
    const [residenceProof, setResidenceProof] = useState('');
    const [bankOrMomoStatement, setBankOrMomoStatement] = useState('');
    const [relManager, setRelManager] = useState('');
    const [guarantorFirstName, setGuarantorFirstName] = useState('');
    const [guarantorLastName, setGuarantorLastName] = useState('');
    const [guarantorPhone, setGuarantorPhone] = useState('');
    const [guarantorAddress, setGuarantorAddress] = useState('');
    const [guarantorRelationship, setGuarantorRelationship] = useState('');
    const guarantorIdType = 'GhanaCard';
    const [guarantorIdNumber, setGuarantorIdNumber] = useState('');
    const [guarantorDigitalAddress, setGuarantorDigitalAddress] = useState('');
    const [guarantorDob, setGuarantorDob] = useState('');
    const [nextOfKin, setNextOfKin] = useState('');
    const [nextOfKinPhone, setNextOfKinPhone] = useState('');
    const [nextOfKinAddress, setNextOfKinAddress] = useState('');
    const [nextOfKinRelationship, setNextOfKinRelationship] = useState('');
    const signedName = client?.name;
    const [processing, setProcessing] = useState(false);
    const [preCheckData, setPreCheckData] = useState({});


    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);

        let data = { phone: client?.phone, paymentPlan, autoDeduction : "Mobile Money Auto-Deduction", residenceProof, bankOrMomoStatement, relManager, guarantorFirstName, guarantorLastName, guarantorPhone, guarantorAddress, guarantorRelationship, guarantorIdType, guarantorIdNumber, guarantorDigitalAddress, guarantorDob, nextOfKin, nextOfKinPhone, nextOfKinAddress, nextOfKinRelationship, signedName }

        // check to see if files have been uploaded
        let bankFile = document.getElementById('bankOrMomoStatement');

        if(!bankFile.files.length > 0){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Must attach bank or momo statement"
            }))
            return;
        }

        if(bankFile.files[0].size > 5000000){
            setProcessing(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "File size must be less than 5MB"
            }))
            return;
        }

        // only submit if loan passes pre-check
        let response = await preCheckLoanEligibility("cediscredit", data);
        setPreCheckData({ ...response, data });

        setTimeout(() => {
            setProcessing(false);
            openModal();
        },500);
    }

    return (
        <>
        <form className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-2' encType={'multipart/form-data'} onSubmit={handleRequest}>

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6 dark:text-white'>
                    Loan Information
                </h3>
            </div>

            <FormSelect 
                label='What is the purpose of the loan?'
                name='purpose'
                id="purpose"
                content={purposeOfLoanOpts}
                value={purpose}
                setValue={setPurpose}
            />

            <FormSelect 
                label='What is the desired payment plan?'
                name='paymentPlan'
                id="paymentPlan"
                content={paymentPlanOpts.slice(0, 1)}
                value={paymentPlan}
                setValue={setPaymentPlan}
            />

            <FormSelect 
                label='Kindly select a relationship manager'
                name='relManager'
                id="relManager"
                content={relationshipManagers}
                value={relManager}
                setValue={setRelManager}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6 dark:text-white'>
                    Guarantor Information
                </h3>
            </div>

            <FormInput 
                label="Guarantor's first name"
                name='guarantorFirstName'
                id="guarantorFirstName"
                type='text'
                value={guarantorFirstName}
                setValue={setGuarantorFirstName}
            />

            <FormInput 
                label="Guarantor's last name"
                name='guarantorLastName'
                id="guarantorLastName"
                type='text'
                value={guarantorLastName}
                setValue={setGuarantorLastName}
            />

            <FormInput 
                label="Guarantor's phone number"
                name='guarantorPhone'
                id="guarantorPhone"
                type='text'
                value={guarantorPhone}
                setValue={setGuarantorPhone}
            />

            <FormInput 
                label="Guarantor's address"
                name='guarantorAddress'
                id="guarantorAddress"
                type='text'
                value={guarantorAddress}
                setValue={setGuarantorAddress}
            />

            <FormInput 
                label="Your relationship to guarantor"
                name='guarantorRelationship'
                id="guarantorRelationship"
                type='text'
                value={guarantorRelationship}
                setValue={setGuarantorRelationship}
            />

            <FormInput 
                label="Guarantor's GhanaCard number"
                name='guarantorIdNumber'
                id="guarantorIdNumber"
                type='text'
                value={guarantorIdNumber}
                setValue={setGuarantorIdNumber}
            />

            <FormInput 
                label="Guarantor's digital address"
                name='guarantorDigitalAddress'
                id="guarantorDigitalAddress"
                type='text'
                value={guarantorDigitalAddress}
                setValue={setGuarantorDigitalAddress}
            />

            <div>
                <label htmlFor="guarantorDob" className='form-label'>
                    Guarantor's date of birth
                </label>
                <input 
                    type="date"
                    name={"guarantorDob"}
                    id={"guarantorDob"}
                    className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                    value={guarantorDob}
                    onChange={e => setGuarantorDob(e.target.value)}
                    min={"1930-01-01"}
                    max={new Date().toISOString().split('T')[0]}
                />
            </div>

            <NextOfKin 
                nextOfKin={nextOfKin}
                setNextOfKin={setNextOfKin}
                nextOfKinPhone={nextOfKinPhone}
                setNextOfKinPhone={setNextOfKinPhone}
                nextOfKinAddress={nextOfKinAddress}
                setNextOfKinAddress={setNextOfKinAddress}
                nextOfKinRelationship={nextOfKinRelationship}
                setNextOfKinRelationship={setNextOfKinRelationship}
            />

            <div className='col-span-full mt-10'>
                <h3 className='text-xl font-medium leading-6 dark:text-white'>
                    Files to upload
                </h3>
            </div>

            <FormUpload 
                label='Upload bank statement or momo statement'
                name='bankOrMomoStatement'
                id="bankOrMomoStatement"
                value={bankOrMomoStatement}
                setValue={setBankOrMomoStatement}
                required={true}
            />

            <FormUpload 
                label='Upload proof of residence'
                name='residenceProof'
                id="residenceProof"
                value={residenceProof}
                setValue={setResidenceProof}
                required={false}
            />

            <LoanAuth />

            <FormInput 
                label='Signed Name'
                name='signedName'
                id="signedName"
                type='text'
                value={signedName}
                disabled={true}
            />

            <div className="col-span-full mt-2">
                <div className="inline-flex items-center gap-2">
                    <Button 
                        btnText={"Check eligibility"}
                        btnType={"submit"}
                        btnClasses={"text-sm bg-light-blue px-3 py-2 rounded-md text-white"}
                        processing={processing}
                    />
                </div>
            </div>

        </form>

        <Modal isOpen={isOpen} closeModal={closeModal}>
            <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 flex items-center mb-4">
                    Pre-Check Status: <span className={`ml-1 px-3 py-1.5 text-white font-medium rounded-full text-xs capitalize ${preCheckData?.status === 'accepted' ? 'bg-emerald-400' : preCheckData?.status === 'rejected' ? 'bg-red-400' : ''}`}>{preCheckData?.status}</span>
                </Dialog.Title>
                <EligCheck 
                    status={preCheckData?.status}
                    data={preCheckData}
                    loanType={"cediscredit"}
                    closeModal={closeModal}
                />
            </Dialog.Panel>
        </Modal>
        </>
    )
}

export default CedisCredit